import { PostVotes } from './PostVotes';
import { PostMenu } from './PostMenu';
import { useState } from 'react';
import { GiftGallery } from './GiftGallery';
import { ForwardPost } from './ForwardPost';
import { Send } from 'lucide-react';

interface PostFooterProps {
  id: string;
  authorId: string;
  likes: string[];
  stars: string[];
  trash: string[];
  matches: string[];
  forwards?: string[];
  onBlock: () => void;
  isDarkMode?: boolean;
  language?: string;
}

export function PostFooter({ id, authorId, likes, stars, trash, matches, forwards = [], onBlock, isDarkMode, language }: PostFooterProps) {
  const [showGiftGallery, setShowGiftGallery] = useState(false);
  const [showForwardModal, setShowForwardModal] = useState(false);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <PostVotes
          postId={id}
          authorId={authorId}
          initialVotes={{ likes, stars, trash, matches }}
          onGiftClick={() => setShowGiftGallery(true)}
          language={language}
        />
        <button
          onClick={() => setShowForwardModal(true)}
          className={`flex items-center space-x-1 ${
            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'
          } transition-colors`}
          title="Forward post"
        >
          <Send className="h-5 w-5" />
          {forwards.length > 0 && (
            <span className="text-sm">{forwards.length}</span>
          )}
        </button>
      </div>
      <PostMenu postId={id} authorId={authorId} onBlock={onBlock} />

      {showGiftGallery && (
        <GiftGallery
          postId={id}
          authorId={authorId}
          onClose={() => setShowGiftGallery(false)}
          isDarkMode={isDarkMode}
          language={language}
        />
      )}

      {showForwardModal && (
        <ForwardPost
          postId={id}
          onClose={() => setShowForwardModal(false)}
          isDarkMode={isDarkMode}
          language={language}
        />
      )}
    </div>
  );
}