import { useState, useEffect } from 'react';
import { Search, X, Loader2, Send, Star } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { Link } from 'react-router-dom';
import { searchUsers } from '../utils/search';
import toast from 'react-hot-toast';
import { collection, addDoc, serverTimestamp, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface ForwardPostProps {
  postId: string;
  onClose: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Encaminhar Publicação',
    searchPlaceholder: 'Buscar usuários...',
    messagePlaceholder: 'Adicione uma mensagem (opcional)...',
    loading: 'Carregando...',
    noResults: 'Nenhum usuário encontrado',
    noResultsFor: 'Nenhum usuário encontrado para',
    searchUsers: 'Buscar Usuários',
    enterName: 'Digite um nome ou nome de usuário para buscar',
    forward: 'Encaminhar',
    forwarding: 'Encaminhando...',
    success: 'Publicação encaminhada com sucesso!',
    errors: {
      searchFailed: 'Falha ao buscar usuários',
      forwardFailed: 'Falha ao encaminhar publicação',
      noLinks: 'Links não são permitidos na mensagem',
      messageTooLong: 'Mensagem deve ter no máximo 120 caracteres'
    },
    characters: 'caracteres',
    favorites: 'Favoritos',
    addToFavorites: 'Adicionar aos favoritos',
    removeFromFavorites: 'Remover dos favoritos',
    showFavorites: 'Mostrar favoritos',
    showAll: 'Mostrar todos',
    loadingFavorites: 'Carregando favoritos...',
    noFavorites: 'Nenhum usuário favorito',
    yourFavorites: 'Seus Favoritos'
  },
  'en': {
    title: 'Forward Post',
    searchPlaceholder: 'Search users...',
    messagePlaceholder: 'Add a message (optional)...',
    loading: 'Loading...',
    noResults: 'No users found',
    noResultsFor: 'No users found for',
    searchUsers: 'Search Users',
    enterName: 'Enter a name or username to search',
    forward: 'Forward',
    forwarding: 'Forwarding...',
    success: 'Post forwarded successfully!',
    errors: {
      searchFailed: 'Failed to search users',
      forwardFailed: 'Failed to forward post',
      noLinks: 'Links are not allowed in messages',
      messageTooLong: 'Message must be 120 characters or less'
    },
    characters: 'characters',
    favorites: 'Favorites',
    addToFavorites: 'Add to favorites',
    removeFromFavorites: 'Remove from favorites',
    showFavorites: 'Show favorites',
    showAll: 'Show all',
    loadingFavorites: 'Loading favorites...',
    noFavorites: 'No favorite users',
    yourFavorites: 'Your Favorites'
  }
};

export function ForwardPost({ postId, onClose, isDarkMode = false, language = 'en' }: ForwardPostProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [forwarding, setForwarding] = useState(false);
  const { user } = useAuth();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [favoriteUsers, setFavoriteUsers] = useState<string[]>([]);
  const [favoriteUserDetails, setFavoriteUserDetails] = useState<any[]>([]);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

  // Detect user's language on mount
  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setCurrentLanguage('pt-BR');
    }
  }, []);

  // Load favorite users from localStorage
  useEffect(() => {
    if (!user) return;
    
    try {
      const storedFavorites = localStorage.getItem(`poosting_favorite_users_${user.uid}`);
      if (storedFavorites) {
        const favorites = JSON.parse(storedFavorites);
        setFavoriteUsers(favorites);
        loadFavoriteUserDetails(favorites);
      }
    } catch (error) {
      console.error('Error loading favorite users:', error);
    }
  }, [user]);

  // Load details for favorite users
  const loadFavoriteUserDetails = async (favoriteIds: string[]) => {
    if (!favoriteIds.length || !user) return;
    
    setLoadingFavorites(true);
    try {
      const details = await Promise.all(
        favoriteIds.map(async (userId) => {
          try {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              return {
                uid: userDoc.id,
                ...userDoc.data()
              };
            }
            return null;
          } catch (error) {
            console.error(`Error fetching user ${userId}:`, error);
            return null;
          }
        })
      );
      
      setFavoriteUserDetails(details.filter(Boolean));
    } catch (error) {
      console.error('Error loading favorite user details:', error);
    } finally {
      setLoadingFavorites(false);
    }
  };

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[currentLanguage as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (searchQuery) {
      const timer = setTimeout(() => {
        handleSearch();
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setUsers([]);
    }
  }, [searchQuery]);

  const handleSearch = async () => {
    if (!searchQuery.trim() || !user) return;

    try {
      setLoading(true);
      // Use the same searchUsers function from utils/search
      const results = await searchUsers(searchQuery, user.uid);
      setUsers(results);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error(t('errors.searchFailed'));
    } finally {
      setLoading(false);
    }
  };

  const validateMessage = (text: string): boolean => {
    // Check for links in message
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    return !urlRegex.test(text);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = e.target.value;
    
    if (newMessage.length > 120) {
      toast.error(t('errors.messageTooLong'));
      return;
    }
    
    if (!validateMessage(newMessage)) {
      toast.error(t('errors.noLinks'));
      return;
    }
    
    setMessage(newMessage);
  };

  const handleForward = async () => {
    if (!selectedUser || !user || forwarding) return;

    try {
      setForwarding(true);
      
      // Get post data
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);
      
      if (!postDoc.exists()) {
        toast.error('Post not found');
        return;
      }
      
      const postData = postDoc.data();
      
      // Create notification
      await addDoc(collection(db, 'notifications'), {
        type: 'post_forward',
        postId,
        recipientId: selectedUser,
        senderId: user.uid,
        senderName: user.displayName || '',
        senderUsername: user.displayName?.replace('@', '') || '',
        senderPhotoURL: user.photoURL,
        postContent: postData.content,
        postImageUrl: postData.imageUrl,
        content: message.trim(), // Add the message to the notification
        timestamp: serverTimestamp(),
        read: false
      });

      // Update post forwards count
      await updateDoc(postRef, {
        forwards: arrayUnion(user.uid)
      });

      toast.success(t('success'));
      onClose();
    } catch (error) {
      console.error('Error forwarding post:', error);
      toast.error(t('errors.forwardFailed'));
    } finally {
      setForwarding(false);
    }
  };

  const toggleFavorite = (userId: string) => {
    if (!user) return;
    
    let updatedFavorites: string[];
    
    if (favoriteUsers.includes(userId)) {
      // Remove from favorites
      updatedFavorites = favoriteUsers.filter(id => id !== userId);
    } else {
      // Add to favorites
      updatedFavorites = [...favoriteUsers, userId];
    }
    
    setFavoriteUsers(updatedFavorites);
    
    // Update favorite user details
    if (!favoriteUsers.includes(userId)) {
      // If adding to favorites, add to details
      const userToAdd = users.find(u => u.uid === userId);
      if (userToAdd) {
        setFavoriteUserDetails(prev => [...prev, userToAdd]);
      }
    } else {
      // If removing from favorites, remove from details
      setFavoriteUserDetails(prev => prev.filter(u => u.uid !== userId));
    }
    
    // Save to localStorage
    try {
      localStorage.setItem(`poosting_favorite_users_${user.uid}`, JSON.stringify(updatedFavorites));
    } catch (error) {
      console.error('Error saving favorite users:', error);
    }
  };

  const filteredUsers = showOnlyFavorites 
    ? users.filter(user => favoriteUsers.includes(user.uid))
    : users;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <div className="p-4">
          {/* Favorite Users Section */}
          {favoriteUsers.length > 0 && (
            <div className="mb-6">
              <h3 className={`text-sm font-medium mb-3 flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                <Star className={`h-4 w-4 mr-2 text-yellow-500 ${isDarkMode ? 'text-opacity-90' : ''}`} />
                {t('yourFavorites')}
              </h3>
              
              {loadingFavorites ? (
                <div className="flex justify-center py-4">
                  <Loader2 className={`h-5 w-5 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                  <span className={`ml-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {t('loadingFavorites')}
                  </span>
                </div>
              ) : favoriteUserDetails.length > 0 ? (
                <div className="grid grid-cols-2 gap-2 mb-4">
                  {favoriteUserDetails.map((favoriteUser) => (
                    <div
                      key={favoriteUser.uid}
                      onClick={() => setSelectedUser(favoriteUser.uid === selectedUser ? null : favoriteUser.uid)}
                      className={`flex items-center p-2 ${
                        isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
                      } rounded-lg transition-colors cursor-pointer ${
                        selectedUser === favoriteUser.uid ? 
                          isDarkMode ? 'bg-blue-900/30 border border-blue-500' : 'bg-blue-50 border border-blue-200' 
                          : ''
                      }`}
                    >
                      <img
                        src={favoriteUser.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${favoriteUser.uid}`}
                        alt={favoriteUser.name}
                        className="h-8 w-8 rounded-full mr-2"
                      />
                      <div className="flex-1 min-w-0">
                        <p className={`text-sm font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                          @{favoriteUser.username}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mb-4`}>
                  {t('noFavorites')}
                </p>
              )}
              
              <div className={`h-px w-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} my-4`}></div>
            </div>
          )}

          <div className="relative mb-4">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              autoFocus
            />
          </div>

          {users.length > 0 && (
            <div className="flex justify-between items-center mb-2">
              <button
                onClick={() => setShowOnlyFavorites(!showOnlyFavorites)}
                className={`text-sm ${
                  isDarkMode 
                    ? 'text-blue-400 hover:text-blue-300' 
                    : 'text-blue-500 hover:text-blue-600'
                }`}
              >
                {showOnlyFavorites ? t('showAll') : t('showFavorites')}
              </button>
              <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {showOnlyFavorites && (
                  <span>{t('favorites')}: {filteredUsers.length}</span>
                )}
              </div>
            </div>
          )}

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            </div>
          ) : filteredUsers.length > 0 ? (
            <div className="max-h-60 overflow-y-auto mb-4">
              {filteredUsers.map((user) => (
                <div
                  key={user.uid}
                  className={`flex items-center justify-between p-3 ${
                    isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
                  } rounded-lg transition-colors ${
                    selectedUser === user.uid ? 
                      isDarkMode ? 'bg-blue-900/30 border border-blue-500' : 'bg-blue-50 border border-blue-200' 
                      : ''
                  }`}
                >
                  <div 
                    className="flex items-center space-x-3 flex-1 cursor-pointer"
                    onClick={() => setSelectedUser(user.uid === selectedUser ? null : user.uid)}
                  >
                    <img
                      src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`}
                      alt={user.name}
                      className="h-10 w-10 rounded-full"
                    />
                    <div className="flex-1 min-w-0">
                      <p className={`font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {user.name}
                      </p>
                      <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        @{user.username}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => toggleFavorite(user.uid)}
                    className={`p-2 rounded-full transition-colors ${
                      favoriteUsers.includes(user.uid)
                        ? 'text-yellow-500 hover:bg-yellow-100/10'
                        : isDarkMode
                          ? 'text-gray-400 hover:text-yellow-500 hover:bg-gray-700'
                          : 'text-gray-400 hover:text-yellow-500 hover:bg-gray-100'
                    }`}
                    title={favoriteUsers.includes(user.uid) ? t('removeFromFavorites') : t('addToFavorites')}
                  >
                    <Star className={`h-5 w-5 ${favoriteUsers.includes(user.uid) ? 'fill-current' : ''}`} />
                  </button>
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} py-8`}>
              {t('noResultsFor')} "{searchQuery}"
            </p>
          ) : (
            <div className="text-center py-8">
              <Search className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
              <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {t('searchUsers')}
              </p>
              <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('enterName')}
              </p>
            </div>
          )}

          {/* Message input field */}
          <div className="mb-4">
            <input
              type="text"
              value={message}
              onChange={handleMessageChange}
              placeholder={t('messagePlaceholder')}
              maxLength={120}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
            <p className={`text-xs mt-1 ${
              message.length > 120 
                ? 'text-red-500' 
                : isDarkMode 
                  ? 'text-gray-400' 
                  : 'text-gray-500'
            }`}>
              {message.length}/120 {t('characters')}
            </p>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={handleForward}
              disabled={!selectedUser || forwarding}
              className={`px-6 py-2 rounded-lg disabled:opacity-50 flex items-center space-x-2 ${
                isDarkMode 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              {forwarding ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>{t('forwarding')}</span>
                </>
              ) : (
                <>
                  <Send className="h-4 w-4" />
                  <span>{t('forward')}</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}